(function() {
    'use strict';

    angular
        .module('app.layout')
        .controller('mainCtrl', mainCtrl)
        .run(mainRun);

    /* @ngInject */
    function mainCtrl($scope) {

        $scope.orderBy = { asc: 'ascending', desc: 'descending' };
        $scope.limits = { 10: '10pp', 20: '20pp', 40: '40pp' };
        $scope.filter = {
            order: 'desc',
            limit: '20'
        };

        $scope.rxData = [];

        var socket = Rx.DOM.fromWebSocket(
            'wss://laborix.undroid.com:8080/echo',
            null,
            Rx.Observer.create(function() {
                socket.onNext(angular.toJson({ key: 'value' }));
            })
        );

        $scope.$watch($scope.filter, function(nVal, oVal) {
            console.log(['filters watch', nVal, oVal]);
            // socket.onNext(angular.toJson({ key: 'value' }));
        }, true);

        socket.subscribe(function(next) {
            console.log('Received data: ' + next.data);
            var nextData = angular.fromJson(next.data);
            $scope.$apply(function() {
                $scope.rxData.push({
                    timeStamp: next.timeStamp,
                    data: nextData
                });
            });
        });

    }

    /* @ngInject */
    function mainRun($rootScope) {
    }

})();