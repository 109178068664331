(function() {
    'use strict';

    angular
        .module('app.core')
        .run(run);

    /* @ngInject */
    function run($rootScope, $window, $location) {

        var rsp = {
            vpn: { min:    0, max:  479, chk: false, to: false , from: false },
            hpn: { min:  480, max:  767, chk: false, to: false , from: false },
            apn: { min:    0, max:  767, chk: false, to: false , from: false },
            vpd: { min:  768, max:  895, chk: false, to: false , from: false },
            hpd: { min:  896, max: 1024, chk: false, to: false , from: false },
            apd: { min:  768, max: 1024, chk: false, to: false , from: false },
            mbl: { min:    0, max: 1024, chk: false, to: false , from: false },
            ltp: { min: 1025, max: 1199, chk: false, to: false , from: false },
            dtp: { min: 1200, max: 1919, chk: false, to: false , from: false },
            fhd: { min: 1920, max: 2559, chk: false, to: false , from: false },
            qhd: { min: 2560, max: 3839, chk: false, to: false , from: false },
            uhd: { min: 3840, max: 9999, chk: false, to: false , from: false }
        };

        for(var res in rsp) { var rso = rsp[res];
            rsp[res].mediaTo = (rso.max != 9999) ? '(max-width: ' + rso.max + 'px)' : '';
            rsp[res].mediaFr = (rso.min != 0) ? '(min-width: ' + rso.min + 'px)' : '';
        }

        $rootScope.win = {
            width: window.innerWidth, height: window.innerHeight,
            orientation: (window.innerWidth / window.innerHeight >= 1) ? 'landscape' : 'portrait'
        };

        $rootScope.rsp = rsp;

        var debounce = function(func, wait, immediate) {
            var timeout;
            return function() {
                var context = this, args = arguments;
                // console.log(['debounce']);
                $window.clearTimeout(timeout);
                timeout = $window.setTimeout(function() {
                    timeout = null;
                    if(!immediate) { func.apply(context, args); }
                }, wait);
                if(immediate && !timeout) { func.apply(context, args); }
            };
        },

        throttle = function(func, threshhold) {
            var last, timer = null;
            return function () {
                var context = this, args = arguments, now = +new Date();
                // console.log(['throttle', now]);
                if(last && now < last + threshhold) {
                    $window.clearTimeout(timer);
                    timer = $window.setTimeout(function() {
                        last = now;
                        func.apply(context, args);
                    }, threshhold);
                } else {
                    last = now;
                    func.apply(context, args);
                }
            };
        },

        debouncedOnResize = debounce(function(e) { // console.log(['debounced onResize', e]);
            $rootScope.$broadcast('debouncedResize', e);
        }, 150),

        debouncedOnScroll = debounce(function(e) { // console.log(['debounced onScroll', e]);
            $rootScope.$broadcast('debouncedScroll', e);
        }, 150),

        throttledOnResize = throttle(function(e) { // console.log(['throttled onResize', e]);
            $rootScope.$broadcast('throttledResize', e);
        }, 75),

        throttledOnScroll = throttle(function(e) { // console.log(['throttled onScroll', e]);
            $rootScope.$broadcast('throttledScroll', e);
        }, 75);

        function onResize(e) {
            $rootScope.win = {
                width: window.innerWidth, height: window.innerHeight,
                orientation: (window.innerWidth / window.innerHeight >= 1) ? 'landscape' : 'portrait',
                scrollLeft: window.scrollX, scrollTop: window.scrollY
            };
            for(var res in $rootScope.rsp) { var rso = $rootScope.rsp[res];
                $rootScope.rsp[res].to   = (rso.mediaTo !== '') ? window.matchMedia(rso.mediaTo).matches : true;
                $rootScope.rsp[res].from = (rso.mediaFr !== '') ? window.matchMedia(rso.mediaFr).matches : true;
                $rootScope.rsp[res].chk  = $rootScope.rsp[res].to && $rootScope.rsp[res].from;
            }
            debouncedOnResize(e); throttledOnResize(e);
        }

        onResize();
        if(window.attachEvent) { window.attachEvent('onresize', onResize); }
        else if(window.addEventListener) { window.addEventListener('resize', onResize, false); }

        function onScroll(e) {
            $rootScope.win.scrollLeft = window.scrollX;
            $rootScope.win.scrollTop = window.scrollY;
            debouncedOnScroll(e); throttledOnScroll(e);
        }

        if(window.attachEvent) { window.attachEvent('onscroll', onScroll); }
        else if(window.addEventListener) { window.addEventListener('scroll', onScroll, false); }

    }

})();