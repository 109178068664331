(function() {
    'use strict';

    var core = angular.module('app.core');

    core.config(configure);

    /* @ngInject */
    function configure($logProvider, $locationProvider, $httpProvider) {

        // turn debugging off/on (no info or warn)
        if($logProvider.debugEnabled) { $logProvider.debugEnabled(true); }

        var param = function(obj) {
            var query = '', name, value, fullSubName, subName, subValue, innerObj, i;
            for(name in obj) { value = obj[name];
                if(value instanceof Array) {
                    for(i = 0; i < value.length; ++i) {
                        subValue = value[i]; fullSubName = name + '[' + i + ']';
                        innerObj = {}; innerObj[fullSubName] = subValue; query += param(innerObj) + '&';
                    }
                } else if(value instanceof Object) {
                    for(subName in value) {
                        subValue = value[subName]; fullSubName = name + '[' + subName + ']';
                        innerObj = {}; innerObj[fullSubName] = subValue; query += param(innerObj) + '&';
                    }
                } else if(value !== undefined && value !== null) {
                    query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
                }
            }
            return query.length ? query.substr(0, query.length - 1) : query;
        };

        $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        $httpProvider.defaults.transformRequest = [function(data) {
            return angular.isObject(data) && String(data) !== '[object File]' ? param(data) : data;
        }];

    }

})();